<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Tickets" subtitle="See all your event tickets that you have either purchased, claimed, or received." />

	<app-content-body :loading="is.loading && is.initialised" :is-grid="(window.is.modile) ? 1 : 3" :is-empty="!collection.length" placeholder="Sorry, no tickets found.">

		<com-ticket v-for="ticket in collection" :key="ticket.id" :item="ticket" />

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	components: {
		'com-ticket': () => import('./conventionregistrationtickets/Ticket')	
	},

	data: function() {

		return {
			pagination: false,
			endpoint: 'convention/registration/tickets'
		}

	}

}

</script>

<style scoped>

</style>